import React from 'react'
import { ContentWrapper } from '@elements/content-wrapper'
import PropertySearchBar from '@elements/property-search/property-search-bar-container'

export const FullWidthPropertySearchBar = () => (
  <div className="relative mb-6 bg-[url('/images/bg-add-property-mobile.png')] bg-cover bg-top py-16 md:mb-12 md:mt-16 md:bg-[url('/images/bg-add-property-desktop.png')] md:py-24">
    <div className="absolute inset-0 bg-black opacity-50 md:opacity-60"></div>
    <div className="-bottom-7 left-0 right-0 md:absolute">
      <ContentWrapper>
        <div className="relative z-10 mb-4 w-full text-xl font-semibold text-white md:mb-10 md:text-center md:text-3xl md:font-normal">
          Ready to buy or sell?
        </div>
        <PropertySearchBar />
      </ContentWrapper>
    </div>
  </div>
)
