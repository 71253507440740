import { checklistApi } from '@redux/apis/checklist'
import { homeApi } from '@redux/apis/home'
import { profileApi } from '@redux/apis/profile'
import { sellChecklistApi } from '@redux/apis/sell-checklist'
import { sellPropertyApi } from '@redux/apis/sell-property'
import { rtkQueryToStore } from '@redux/listeners'
import { setChecklists } from '@redux/reducers/checklist'
import { setWorkParty, setHomeBuyingJourneys } from '@redux/reducers/home'
import { setProfile } from '@redux/reducers/profile'
import { setSellChecklists, setSellProperties } from '@redux/reducers/sell'

export const apiListeners = () => {
  /**
   * Put any side effects here where you want to map the latest value of an RTK query event to the store
   */
  rtkQueryToStore(profileApi.endpoints.getProfile.matchFulfilled, setProfile)
  rtkQueryToStore(homeApi.endpoints.getHomeBuyingJourney.matchFulfilled, setHomeBuyingJourneys)
  rtkQueryToStore(checklistApi.endpoints.getChecklist.matchFulfilled, setChecklists)
  rtkQueryToStore(homeApi.endpoints.getTeam.matchFulfilled, setWorkParty)

  rtkQueryToStore(sellPropertyApi.endpoints.getSellProperties.matchFulfilled, setSellProperties)
  rtkQueryToStore(sellChecklistApi.endpoints.getAllSellChecklists.matchFulfilled, setSellChecklists)
}
