import { ImageProps } from 'next/image'
import React from 'react'

import SVG from 'react-inlinesvg'
import { classNames } from '@utils/helpers/classNameHelper'

interface IllustrationProps extends Partial<ImageProps> {
  asset: Illustrations
  size?: number
  customColour?: string
}

export enum Illustrations {
  alert = '/illustrations/alert.svg',
  calendar = '/illustrations/calendar.svg',
  checkbox = '/illustrations/checkbox.svg',
  checklist = '/illustrations/checklist.svg',
  clock = '/illustrations/clock.svg',
  contract = '/illustrations/contract.svg',
  conveyancing = '/illustrations/conveyancing.svg',
  direction = '/illustrations/direction.svg',
  document = '/illustrations/document.svg',
  emojiSad = '/illustrations/emoji_sad.svg',
  emojiSadGray = '/illustrations/emoji_sad_gray.svg',
  emojiHappy = '/illustrations/emoji_happy.svg',
  emojiHappyGray = '/illustrations/emoji_happy_gray.svg',
  feedback = '/illustrations/feedback.svg',
  fingerprint = '/illustrations/fingerprint.svg',
  help = '/illustrations/help.svg',
  home = '/illustrations/home.svg',
  homeAdd = '/illustrations/home_add.svg',
  homeDollar = '/illustrations/home_dollar.svg',
  homeInLogo = '/illustrations/homein_logo.svg',
  homeInLogoTransparent = '/illustrations/homein_logo_transparent.svg',
  homeTick = '/illustrations/home_tick.svg',
  inbox = '/illustrations/inbox.svg',
  info = '/illustrations/info.svg',
  key = '/illustrations/key.svg',
  legal = '/illustrations/legal.svg',
  lock = '/illustrations/lock.svg',
  logout = '/illustrations/logout.svg',
  messages = '/illustrations/messages.svg',
  mobile = '/illustrations/mobile.svg',
  overview = '/illustrations/overview.svg',
  people = '/illustrations/people.svg',
  person = '/illustrations/person.svg',
  place = '/illustrations/place.svg',
  purchase = '/illustrations/purchase.svg',
  rotate = '/illustrations/rotate.svg',
  scan = '/illustrations/scan.svg',
  seal = '/illustrations/seal.svg',
  support = '/illustrations/support.svg',
  tick = '/illustrations/tick.svg',
  unlock = '/illustrations/unlock.svg',
  value = '/illustrations/value.svg',
  video = '/illustrations/video.svg',
  list = '/illustrations/list.svg',
  homeAddProperty = '/illustrations/home_add_property.svg',
  friends = '/illustrations/friends.svg',
  timeline = '/illustrations/timeline.svg',
  qr = '/illustrations/qr.svg',
}

const Illustration = ({ asset, size = 40, className, customColour }: IllustrationProps) => {
  return (
    <div className={classNames('', className)} style={{ width: size + 'px', height: size + 'px' }}>
      {!customColour && (
        <SVG
          className={classNames('w-full h-full')}
          src={asset}
          //remove defs as we loaded them elsewhere
          preProcessor={(code) => code.replace(/<defs>((.|\n)*?)<\/defs>/g, '')}
        />
      )}
      {customColour && (
        <SVG
          className={classNames(`w-full h-full text-${customColour}`)}
          src={asset}
          preProcessor={(code) => {
            return (
              code
                //remove defs as we loaded them elsewhere
                .replace(/<defs>((.|\n)*?)<\/defs>/g, '')
                .replace(/fill="(?!none).+?"/g, 'fill="currentColor"')
                .replace(/stroke="(?!none).+?"/g, 'stroke="currentColor"')
            )
          }}
        />
      )}
    </div>
  )
}

// we need to load all the svg defs separately, otherwise we get conflicting IDs
export const SvgDefs = () => {
  return (
    <div>
      {Object.values(Illustrations).map((asset) => (
        <SVG
          key={asset}
          src={asset}
          preProcessor={(code) => {
            let defs = /<defs>((.|\n)*?)<\/defs>/g.exec(code)
            return defs?.length
              ? `<svg width="0" height="0" fill="none" xmlns="http://www.w3.org/2000/svg">${defs[0]}</svg>`
              : ''
          }}
        />
      ))}
    </div>
  )
}

export default Illustration
