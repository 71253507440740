import React, { HTMLAttributes } from 'react'
import { Card } from '@elements/card'
import Illustration, { Illustrations } from '@elements/icons/illustration'
import { classNames } from '@utils/helpers/classNameHelper'

interface IAddPropertyBanner extends HTMLAttributes<HTMLDivElement> {
  heading?: string
  content?: string
}

export const AddPropertyBanner = ({
  className,
  heading = 'Buying a new home?',
  content = 'Unlock your personalised home buying checklist, access quality conveyancing, get a contract reviewed and more!',
}: IAddPropertyBanner) => {
  return (
    <Card className={classNames('flex flex-col items-center', className)}>
      <div className="relative mb-6 h-[185px] w-[185px] rounded-full bg-light">
        <Illustration asset={Illustrations.homeAddProperty} size={175} className="absolute left-2 top-0" />
      </div>
      <div className="flex max-w-3xl flex-col justify-center">
        <span className="mb-6 text-center text-xl font-bold">{heading}</span>
        <span className="mb-6 text-center text-base font-normal">{content}</span>
      </div>
    </Card>
  )
}
