/**
 * Analytics Event names should not be changed since they aim to track the event over time for all analytics platforms.
 *
 * Product and marketing teams rely on these events staying the same for analyzing user behaviour and measuring the performance
 * of marketing campaigns.
 *
 * Changing the values in these enums can create failures in Mixpanel reports, as well as failures in paid and organic marketing campaigns run by marketing.
 *
 * If you do want to change an event name make sure you go through the process outlined in the confluence document here:
 *
 * https://home-in.atlassian.net/l/cp/4TmsDcXc
 */

export enum AnalyticsCategories {
  Checklist = 'Checklist',
  Guidance = 'Guidance',
  LenderSignup = 'Lender Signup',
  PageView = 'Page View',
  Profile = 'Profile',
  Property = 'Property',
  SupportArticle = 'Support Article',
  Team = 'Team',
  FeedbackEmoji = 'FeedbackEmoji',
  ForcedLogout = 'Forced Logout',
  WelcomeScreens = 'Welcome Screens',
  Documents = 'Documents',
  Experiment = 'Experiment',
  ShareHomeIn = 'Share Home-in',
  ClientError = 'Client Error',
  GetInTouch = 'Get in touch',
  HomeScreen = 'Home Screen',
  AcquisitionFunnel = 'Acquisition Funnel',
  Navigation = 'Navigation',
  LenderApp = 'Lender App',
  AddProperty = 'AddProperty',
}

/**
 * The AnalyticsEventAPICallPrefixes are used in combination with AnalyticsEventAPICallEventNames to create the event names.
 *
 * For example the event names for AddProperty are as follows
 *
 * - 'Button Clicked: Add Property'
 * - 'Request Succeeded: Add Property'
 * - 'Request Failed: Add Property'
 */
export enum AnalyticsEventAPICallPrefixes {
  ButtonClick = 'Button Clicked: ',
  RequestStarted = 'Request Started: ',
  RequestSucceeded = 'Request Succeeded: ',
  RequestFailed = 'Request Failed: ',
  ClientError = 'Client Error: ',
}
export enum AnalyticsEventAPICallEventNames {
  AddProperty = 'Add Property',
  AddSellProperty = 'Add Sell Property',
  AddSellChecklist = 'Add Sell Checklist',
  RemoveProperty = 'Remove Property',
  RemoveSellProperty = 'Remove Sell Property',
  CreateAccount = 'Create Account',
  CreateAccountVersionB = 'Create Account Version B',
  CreateAccountWithoutName = 'Create Account Without Name',
  FullName = 'Full name create profile form',
  FirstMiddleLastName = 'First, middle, and last name create profile form',
  NameAndPhoneNumber = 'Name and phone number',
  UpdatedProfile = 'Updated Profile',
  UpdatedPurchaseDetails = 'Updated Purchase Details',
  UpdatedLegalDetails = 'Updated Legal Details',
  AcceptedTermsAndConditions = 'Accepted Terms and Conditions',
  GetGenericUploadUrl = 'Get generic document upload URL',
  UploadGenericDocS3 = 'Upload generic document to S3',
  SubmitGenericDocs = 'Upload generic documents submit',
  AcceptedSellCostTerms = 'Accepted Sell Cost and Terms',
  UpdateBankDetails = 'Update Bank Details',
  AddSellPropertyInfo = 'Add Sell Property Info',
  AddCampaign = 'Add Campaign',
}

// The event names are combined with the AnalyticsEventAPICallPrefixes.ButtonClick prefix
export enum ButtonClickEventNames {
  OpenUploadGenericDocs = 'Open Upload Generic Documents',
  SearchGuidanceArticles = 'Search Guidance Articles',
  RemovePropertyIcon = 'Remove Property Icon',
  NPSModalSurveyOpened = 'NPS Modal Survey Opened',
  NPSModalMaybeLater = 'NPS Modal Maybe Later',
  NPSModalDismissed = 'NPS Modal Dismissed',
  SellSideSettlementSuccesModal = 'Sell Side Settlement Success Modal',
  SellSideAddPropertyCardActioned = 'Sell Side Add Property Card Actioned',
  SellSideAddPropertyCardDismissed = 'Sell Side Add Property Card Dismissed',
  CBAYelloOffer001TileLearnMore = 'Learn more | Offer - Yello offer - 001',
  CBAWorkplaceBankingOffer001TileLearnMore = 'Learn more | Offer - Workplace banking offer - 001',
}

/**
 * TrackAnalyticEventNames are simply the event names found in Mixpanel and Google Tag Manager
 */
export enum TrackAnalyticEventNames {
  LenderReferralSubmitted = 'Lender Referral: Submitted',
  LenderReferralSuccessful = 'Lender Referral: Successful',
  LenderValidationPassed = 'Lender validation: passed',
  LenderValidationFailed = 'Lender validation: failed',
  LenderCHLValidationPassed = 'CHL validation: passed',
  LenderCHLValidationFailed = 'CHL validation: failed',
  SupportArticleHelpful = 'Article Helpful',
  SupportArticleUnhelpful = 'Article Unhelpful',
  SupportArticleGetInTouch = 'Support Article: Get in touch',
  UploadDocFileSelection = 'Upload document: Document selected',
  UploadDocFileSelectionInvalid = 'Upload document: Invalid document selected',
}

/*
  Analytic events for task completion can't be predefined because the task name
  is received dynamically from an API endpoint. Therefore to find the event name
  you need to check Mixpanel reports and play around with the application.
*/
export enum AnalyticEventsTaskCompletion {}
