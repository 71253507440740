const getClientId = (): string => {
  const customerAppAuth0ClientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ''

  return customerAppAuth0ClientId
}

const getRedirectUri = (): string => {
  if (typeof window === 'undefined') return ''

  const originUrl = window?.location?.origin || ''

  return originUrl
}

export { getClientId, getRedirectUri }
