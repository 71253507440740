import { Devices } from '@home-in/models'
import { useEffect, useState } from 'react'
import { isAppOnMobilePlatform } from '../helpers/app-data-bridge'

export const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState<Devices>(Devices.Desktop)

  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      const mobileUserAgent = navigator.userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )

      setDeviceType(mobileUserAgent ? Devices.Mobile : Devices.Desktop)
    }
  }, [])

  return deviceType
}

export const useMobilePlatform = () => {
  const [mobilePlatform, setMobilePlatform] = useState<'Android' | 'iOS' | null>(null)
  useEffect(() => {
    if (navigator.userAgent.match(/Android/)) setMobilePlatform('Android')
    if (navigator.userAgent.match(/iPad|iPhone|iPod/)) setMobilePlatform('iOS')
  }, [])
  return mobilePlatform
}

export const useMobileAppAuth = () => {
  if (typeof window === 'undefined') {
    return
  }

  let tokenExists = !!window?.localStorage.getItem('token')

  return tokenExists ? isAppOnMobilePlatform() : false
}
