import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Link from 'next/link'
import React from 'react'
import { Card } from '@elements/card'

interface AboutCardProps {
  title: string
  description: string
  linkText: string
  linkUrl: string
  external?: boolean
}

const AboutCard: React.FC<AboutCardProps> = ({ title, description, linkText, linkUrl, external = false }) => (
  <Card size="small">
    <div className="text-lg font-medium md:text-xl">{title}</div>
    <div className="py-4 text-muted-700 md:py-5">{description}</div>
    <div className="flex cursor-pointer items-center text-sm text-primary-700 hover:text-primary-800 sm:text-base">
      <Link href={linkUrl} passHref>
        <a className="mr-2" target={external ? '_blank' : '_self'} rel={external ? 'noopener noreferrer' : undefined}>
          {linkText}
        </a>
      </Link>
      {external && (
        <div className="md:text-xl">
          <OpenInNewIcon fontSize="inherit" />
        </div>
      )}
    </div>
  </Card>
)

export const AboutHomeInCards = () => (
  <div className="mt-4 grid grid-cols-2 gap-2 sm:gap-4 lg:grid-cols-3">
    <AboutCard
      title="Our pricing"
      description="Understand how much conveyancing and disbursements cost"
      linkText="Learn about pricing"
      linkUrl="https://www.home-in.com.au/pricing"
      external={true}
    />
    <AboutCard
      title="How it works"
      description="Simply add a property to request a contract review or contract preparation"
      linkText="Learn more"
      linkUrl="#"
    />
  </div>
)
