import React, { FC } from 'react'
import { AddPropertyBanner } from '@elements/banners/add-property-banner'

export const AddPropertyCard: FC = () => {
  return (
    <AddPropertyBanner
      heading="Buying or selling a property?"
      content="Add a property to unlock your personalised checklist, engage your conveyancer and request a contract review or contract preparation. "
    />
  )
}
